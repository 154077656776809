<template>
  <TopBar />
  <NavBar />
  <DefaultBanner
    title="Frequently Asked Questions"
    :class="['lg:faq-banner-bg faq-banner-mobile-bg']"
  />
  <div class="w-100 lg:pt-20 relative overflow-hidden">
    <div
      class="
        absolute
        z-0
        left-0
        right-0
        top-0
        bottom-0
        flex flex-row
        justify-between
        items-center
      "
    >
      <img
        class="lg:w-auto w-full absolute left-0 top-0 hidden lg:block"
        src="/images/map-right.png"
      />
      <img
        class="lg:w-auto w-1/2 absolute left-0 top-0 lg:hidden"
        src="/images/map-right.png"
      />
    </div>
    <div
      class="
        container
        mx-auto
        lg:px-3
        px-5
        py-6
        z-10
        relative
        flex flex-row
        items-stretch
        lg:mb-12
      "
    >
      <FaqList />
    </div>
  </div>

  <div class="w-100 relative overflow-hidden mb-10">
    <div
      class="
        absolute
        z-0
        left-0
        right-0
        top-0
        bottom-0
        flex flex-row
        justify-between
        items-center
      "
    >
      <img
        class="w-auto absolute lg:left-0 lg:top-4 right-0 -bottom-10 block"
        src="/images/dots.png"
      />
      <img
        class="
          w-10/12
          max-w-8xl
          absolute
          right-0
          top-0
          hidden
          lg:block
          h-520px
          object-cover
        "
        src="/images/questions-image.png"
      />
      <img
        class="w-full absolute right-0 top-0 block lg:hidden h-auto"
        src="/images/questions-image-mobile.png"
      />
    </div>
    <div class="px-3 container mx-auto flex relative">
      <div
        class="
          bg-white
          lg:py-10 lg:pl-0 lg:pr-6
          pr-3
          pl-3
          flex flex-col
          lg:mt-40
          mt-48
          lg:mb-28
          mb-14
          items-center
          lg:items-start
          text-center
          w-full
          lg:w-auto
        "
      >
        <p class="font-display font-extrabold text-4xl mt-4">
          STILL HAVE QUESTIONS?
        </p>
        <div class="flex flex-row w-full mt-2 items-center justify-center">
          <p class="uppercase font-display text-4xl">Feel Free To Ask</p>
          <div
            class="border-t-2 flex-grow hidden lg:block border-black-full ml-4"
          ></div>
        </div>
        <div class="flex lg:flex-row flex-col mt-12 mb-6 w-full lg:w-auto">
          <HrefButton
            size="regular"
            icon="question-phone"
            label="+1817-357-8462"
            class="
              bg-blue
              border-blue
              lg:w-80
              w-full
              px-6
              lg:py-2
              py-4
              justify-center
              text-white
              lg:mr-6 lg:text-2xl
              text-xl
              special-shadow
              shadow-gap
              lg:mb-4
              mb-8
            "
          />
          <HrefButton
            size="regular"
            icon="question-chat"
            label="BEGIN A CHAT"
            class="
              border-blue
              lg:w-80
              w-full
              px-6
              lg:py-2
              py-4
              justify-center
              text-blue
              lg:text-2xl
              text-xl
              special-shadow
              shadow-gap
              mb-4
            "
          />
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import TopBar from "../components/TopBar.vue";
import NavBar from "../components/NavBar.vue";
import DefaultBanner from "../components/DefaultBanner.vue";
import HrefButton from "../components/HrefButton.vue";
import Footer from "../components/Footer.vue";
import FaqList from "../components/FaqList.vue";

export default {
  name: "Home",
  components: {
    TopBar,
    NavBar,
    DefaultBanner,
    HrefButton,
    Footer,
    FaqList,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style></style>